<template>
  <el-date-picker
    popper-class="custom-date-picker"
    style="width: 100%"
    v-bind="detectComponent.attrs"
    v-on="detectComponent.events"
  />
</template>

<script>
export default {
  props: {
    formatDate: {
      type: String,
      default: "yyyy-MM-dd'T'HH:mm:ssxxx"
    }
  },
  data() {
    return {
      typesRange: ["datetimerange", "daterange", "monthrange"],
      types: {
        datetimerange: this.defaultPropertyTypeDateRange,
        daterange: this.defaultPropertyTypeDateRange
      }
    };
  },
  computed: {
    detectComponent() {
      const type = this.$attrs?.type;
      if (!this.types[type]) {
        return {
          attrs: this.$attrs,
          events: {
            change: this.onChange,
            ...this.$listeners
          }
        };
      }
      const { attrs, events } = this.types[type] && this.types[type]();
      return {
        attrs: {
          ...attrs,
          ...this.$attrs
        },
        events: {
          ...events,
          ...this.$listeners
        }
      };
    },
    device() {
      return this.$store.state.app.device;
    }
  },
  methods: {
    defaultPropertyTypeDateRange() {
      return {
        attrs: {
          format: `${this.$attrs?.type === "daterange" ? "dd-MM-yyyy" : ""}`,
          "start-placeholder": "Start date",
          "end-placeholder": "End date",
          "default-time": ["00:00:00", "23:59:59"],
          editable: this.device === "desktop" ? true : false
        },
        events: {
          change: this.onChange
        }
      };
    },
    onChange(value) {
      if (this.typesRange.includes(this.$attrs?.type)) {
        if (!value) {
          this.onEmit([null, null]);
          return;
        }
        const [from_date, to_date] = value;
        const from_date_format = this.formatTimeZone(from_date);
        const to_date_format = this.formatTimeZone(to_date);
        this.onEmit([from_date_format, to_date_format]);
        return;
      }

      value && this.onEmit(this.formatTimeZone(value));
      !value && this.onEmit(value);
    },
    formatTimeZone(date) {
      if (!date) return date;
      return this.$helper.formatWithTimeZone(date, this.formatDate);
    },
    onEmit(value) {
      this.$emit("input", value);
      this.$emit("onChange", value);
    }
  }
};
</script>
