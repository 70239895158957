<template>
  <div class="app-container">
    <el-card class="el-card-no-border">
      <keep-alive>
        <component
          :is="tabList"
          :ref="{ tabList }"
          :key="tabList"
          :queryprops.sync="queryprops"
        />
      </keep-alive>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      searchInput: "",
      queryprops: {
        q: "",
      },
    };
  },
  components: {
    "cabinet-list": () => import("./components/CabinetList"),
  },
  computed: {
    tabList: function () {
      return `cabinet-list`;
    },
  },
  watch: {
    "$route.query"() {
      if (Object.keys(this.$route.query).length === 0) {
        this.resetAll();
      }
    },
  },
  created() {
    this.initParams();
  },

  mounted() {},

  ////////////////////////////
  methods: {
    resetAll() {
      this.queryprops = {
        q: "",
      };
      this.searchInput = "";
    },
    handleSearch() {
      this.queryprops.q = this.searchInput;
    },
    initParams() {
      this.queryprops.q = this.searchInput = this.$route.query.q || null;
    },
    formatType(type) {
      return type.replace(/_/g, " ");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.el-card-no-border {
  ::v-deep {
    .el-card__header {
      border-bottom: 0px;
    }
    .el-card__body {
      padding: 40px 20px;
    }
  }
}
</style>
