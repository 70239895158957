var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _vm.loading
        ? _c("CodeLoader", {
            attrs: {
              width: 600,
              height: 80,
              speed: 2,
              "primary-color": "#f3f3f3",
              "secondary-color": "#ecebeb"
            }
          })
        : [
            _c("cabinet-table", { attrs: { tableData: _vm.cabinetList } }),
            _vm._v(" "),
            _c("el-pagination", {
              staticClass: "flex flex-end",
              staticStyle: {
                "margin-top": "20px",
                "justify-content": "flex-end"
              },
              attrs: {
                background: "",
                layout: "total, prev, pager, next",
                "current-page": _vm.currentPage,
                "page-size": _vm.pageSize,
                total: _vm.total
              },
              on: { "current-change": _vm.onChangePage }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }