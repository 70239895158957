import Vue from "vue";

import "normalize.css/normalize.css"; // A modern alternative to CSS resets

import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en"; // lang i18n
import { CodeLoader } from "vue-content-loader";
import SidebarLoader from "@/components/SidebarLoader";
import { Message } from "element-ui";
import "@/styles/index.scss"; // global css
import * as helper from "@/utils/index";
import App from "./App";
import store from "./store";
import router from "./router";

import "@/icons"; // icon
import "@/permission"; // permission control


import * as VueGoogleMaps from "vue2-google-maps";

import "vue-easytable/libs/theme-default/index.css"; // import style
import VueEasytable from "vue-easytable"; // import library

Vue.use(VueEasytable);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCl_thtuSPjbSzMgtzWYMUDxEHRCj466QE",
    libraries: "places", // necessary for places input
    region: "vn"
  }
});

// set ElementUI lang to EN
Vue.use(ElementUI, { locale });
// 如果想要中文版 element-ui，按如下方式声明
// Vue.use(ElementUI)
Vue.prototype.$helper = helper;
Vue.config.productionTip = false;
Vue.component("CodeLoader", CodeLoader);
Vue.component(Message);

new Vue({
  el: "#app",
  router,
  store,
  render: h => h(App)
});


