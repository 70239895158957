import Cookies from "js-cookie";

const tokenKey = "merchants_token";

export function getToken() {
  return Cookies.get(tokenKey);
}

export function setToken(token) {
  let expires = process.env.VUE_APP_EXPIRES_COOKIES || 365
  return Cookies.set(tokenKey, token, { expires: Number(expires) });
}

export function removeToken() {
  return Cookies.remove(tokenKey);
}
export function getIsShowApp() {
  const value = Cookies.get("isShowApproved");
  return value === "true" ? true : false;
}
export function setIsShowApp(payload) {
  return Cookies.set("isShowApproved", payload);
}

export function getIsShowRequest() {
  const value = Cookies.get("isShowRequest");
  return value === "true" ? true : false;
}
export function setIsShowRequest(payload) {
  return Cookies.set("isShowRequest", payload);
}
