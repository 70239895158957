import axios, { get, post, put } from "axios";
import { urlQueryString } from "@/utils";

const prefix = "cabinets";
export default {
  get(id) {
    return get(`${prefix}/${id}`);
  },
  getAll(data) {
    const url = urlQueryString(data, false);
    return get(`${prefix}?${url}`, data);
  },
  addCabinet(data) {
    return post(`${prefix}`, data);
  },
  updateCabinet(id, data) {
    return put(`${prefix}/${id}`, data);
  },
  uploadFile(file, type) {
    var data = new FormData();
    data.append("file", file);
    return post(`static/upload`, data);
  },
  addPhoto(data) {
    return post(`${prefix}/photos`, data);
  },
  deletePhoto(id) {
    return axios.delete(`${prefix}/photos/${id}`);
  },
  togglePocket(id, data) {
    return put(`pockets/${id}`, data);
  },
  getTemplates() {
    return get(`${prefix}/templates`);
  },
  syncInfoCabinet(id) {
    return post(`${prefix}/${id}/sync`);
  }
};
