var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "w-100" },
      [
        _vm.loading
          ? _c(
              "el-card",
              {
                staticClass: "el-card-no-border",
                class: { "height-250": _vm.loading }
              },
              [
                _c("CodeLoader", {
                  attrs: {
                    width: 600,
                    height: 80,
                    speed: 2,
                    "primary-color": "#f3f3f3",
                    "secondary-color": "#ecebeb"
                  }
                })
              ],
              1
            )
          : _c(
              "el-card",
              {
                staticClass: "el-card-no-border",
                class: { "height-250": _vm.loading }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "align-center is-flex justify-space-between header-content",
                    staticStyle: { "flex-wrap": "wrap" },
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "card-title clickable",
                        on: { click: _vm.returnToList }
                      },
                      [
                        _vm._v(
                          "\n          Delivery #" +
                            _vm._s(_vm.selectedDelivery) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticClass: "info-user",
                    staticStyle: { "flex-wrap": "wrap" },
                    attrs: { type: "flex", gutter: 20 }
                  },
                  [
                    _c("el-col", { attrs: { xs: 24, sm: 12 } }, [
                      _c("div", { staticClass: "info-card" }, [
                        _c(
                          "div",
                          { staticClass: "link-name align-center" },
                          [
                            _c("svg-icon", {
                              staticStyle: { width: "42px", height: "42px" },
                              attrs: { "icon-class": "sender" }
                            }),
                            _vm._v(" "),
                            _c("h3", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.deliveryData.sender.phone_number) +
                                  "\n                " +
                                  _vm._s(
                                    _vm.addRoundBrackets(
                                      _vm.deliveryData.sender.name
                                    )
                                  ) +
                                  "\n              "
                              )
                            ])
                          ],
                          1
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { xs: 24, sm: 12 } }, [
                      _c("div", { staticClass: "info-card" }, [
                        _c(
                          "div",
                          { staticClass: "link-name align-center" },
                          [
                            _c("svg-icon", {
                              staticStyle: { width: "42px", height: "42px" },
                              attrs: { "icon-class": "receiver" }
                            }),
                            _vm._v(" "),
                            _c("h3", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.deliveryData.receiver.phone_number
                                  ) +
                                  "\n                " +
                                  _vm._s(
                                    _vm.addRoundBrackets(
                                      _vm.deliveryData.receiver.name
                                    )
                                  ) +
                                  "\n              "
                              )
                            ])
                          ],
                          1
                        )
                      ])
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: _vm.isMobile ? 24 : 12 } }, [
                      _c("h4", [_vm._v("Information")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: { padding: "20px", "padding-top": "0px" }
                        },
                        [
                          _c(
                            "el-row",
                            {
                              staticClass: "field-data",
                              attrs: { type: "flex", align: "bottom" }
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "field-title",
                                  attrs: { span: 6 }
                                },
                                [_vm._v(" Cabinet: ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "field-content",
                                  attrs: { span: 18 }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.deliveryData.cabinet.name) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticClass: "field-data",
                              attrs: { type: "flex", align: "bottom" }
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "field-title",
                                  attrs: { span: 6 }
                                },
                                [_vm._v(" Pocket: ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "field-content",
                                  attrs: { span: 18 }
                                },
                                [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        type: _vm.deliveryData.pocket.state,
                                        size: "mini"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-transform": "capitalize"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.formatType(
                                                  _vm.deliveryData.pocket.state
                                                )
                                              )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-tag",
                                    {
                                      staticClass: "mr-5",
                                      attrs: {
                                        type:
                                          _vm.deliveryData.pocket.status +
                                          "-status",
                                        size: "mini"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-transform": "capitalize"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.deliveryData.pocket.status
                                              )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.deliveryData.extra &&
                                  _vm.deliveryData.extra.pocket &&
                                  _vm.deliveryData.extra.pocket.local_id
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                  Pocket " +
                                            _vm._s(
                                              _vm.deliveryData.extra.pocket
                                                .local_id
                                            ) +
                                            "\n                "
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticClass: "field-data",
                              attrs: { type: "flex", align: "bottom" }
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "field-title",
                                  attrs: { span: 6 }
                                },
                                [_vm._v(" Type: ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "flex-end"
                                  },
                                  attrs: { span: 18 }
                                },
                                [
                                  _c("el-tag", { style: _vm.styleTag }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "text-transform": "capitalize"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.delivery_type[
                                              _vm.deliveryData.type
                                            ] || "Default"
                                          )
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticClass: "field-data",
                              attrs: { type: "flex", align: "bottom" }
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "field-title",
                                  attrs: { span: 6 }
                                },
                                [_vm._v(" Status: ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "flex-end"
                                  },
                                  attrs: { span: 18 }
                                },
                                [
                                  _c(
                                    "el-tag",
                                    {
                                      staticStyle: {
                                        width: "85px",
                                        "text-align": "center"
                                      },
                                      attrs: { type: _vm.deliveryData.status }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-transform": "capitalize"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.deliveryData.status)
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticClass: "field-data",
                              attrs: { type: "flex", align: "bottom" }
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "field-title",
                                  attrs: { span: 6 }
                                },
                                [_vm._v(" Note: ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "field-content",
                                  attrs: { span: 18 }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.deliveryData.note) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticClass: "field-data",
                              attrs: { type: "flex", align: "bottom" }
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "field-title",
                                  attrs: { span: 6 }
                                },
                                [_vm._v(" Sending Fee: ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "field-content bold",
                                  attrs: { span: 18 }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.formatCurrency(
                                          _vm.deliveryData.sending_price
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticClass: "field-data",
                              attrs: { type: "flex", align: "bottom" }
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "field-title",
                                  attrs: { span: 6 }
                                },
                                [_vm._v(" Estimated Fee: ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "field-content bold",
                                  attrs: { span: 18 }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.formatCurrency(
                                          _vm.deliveryData
                                            .estimated_receiving_price
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticClass: "field-data",
                              attrs: { type: "flex", align: "bottom" }
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "field-title",
                                  attrs: { span: 6 }
                                },
                                [_vm._v(" Receiving Fee: ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "field-content bold",
                                  attrs: { span: 18 }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.formatCurrency(
                                          _vm.deliveryData.receiving_price
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticClass: "field-data",
                              attrs: { type: "flex", align: "bottom" }
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "field-title",
                                  attrs: { span: 6 }
                                },
                                [_vm._v(" Charging Fee: ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "field-content bold",
                                  attrs: { span: 18 }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.formatCurrency(
                                          _vm.deliveryData.charged_amount
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticClass: "field-data",
                              attrs: { type: "flex", align: "bottom" }
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "field-title",
                                  attrs: { span: 6 }
                                },
                                [_vm._v(" Refund Amount: ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "field-content bold",
                                  attrs: { span: 18 }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.formatCurrency(
                                          _vm.deliveryData.refunded_amount
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticClass: "field-data",
                              attrs: { type: "flex", align: "bottom" }
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "field-title",
                                  attrs: { span: 6 }
                                },
                                [_vm._v(" Category: ")]
                              ),
                              _vm._v(" "),
                              _vm.deliveryData.package_category
                                ? _c(
                                    "el-col",
                                    {
                                      staticClass: "text-right",
                                      attrs: { span: 18 }
                                    },
                                    [
                                      _c("el-tag", { style: _vm.styleTag }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "is-flex align-center justify-center",
                                            staticStyle: {
                                              "text-transform": "capitalize"
                                            }
                                          },
                                          [
                                            _vm.packageCategoryIcon
                                              ? _c("svg-icon", {
                                                  staticStyle: {
                                                    width: "21px",
                                                    height: "21px"
                                                  },
                                                  attrs: {
                                                    "icon-class":
                                                      _vm.packageCategoryIcon
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "ml-5",
                                                staticStyle: {
                                                  color: "#606266",
                                                  "font-size": "14px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.deliveryData
                                                      .package_category
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: _vm.isMobile ? 24 : 12 } },
                      [
                        _c("h4", [_vm._v("Timeline")]),
                        _vm._v(" "),
                        _c(
                          "el-timeline",
                          _vm._l(_vm.deliveryData.status_logs, function(
                            delivery,
                            index
                          ) {
                            return _c(
                              "el-timeline-item",
                              {
                                key: index,
                                attrs: {
                                  type: delivery.status,
                                  timestamp: _vm.formatDateTime(
                                    delivery.created_at
                                  )
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "text-transform": "capitalize"
                                    }
                                  },
                                  [_vm._v(_vm._s(delivery.status))]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }