var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-date-picker",
    _vm._g(
      _vm._b(
        {
          staticStyle: { width: "100%" },
          attrs: { "popper-class": "custom-date-picker" }
        },
        "el-date-picker",
        _vm.detectComponent.attrs,
        false
      ),
      _vm.detectComponent.events
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }