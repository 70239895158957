/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    return value.toString().padStart(2, "0");
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return (
      d.getMonth() +
      1 +
      "月" +
      d.getDate() +
      "日" +
      d.getHours() +
      "时" +
      d.getMinutes() +
      "分"
    );
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
    decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace(/\+/g, " ") +
    '"}'
  );
}

export function urlQueryString(data, allowNULL = true) {
  const url = Object.keys(data);
  return url
    .map(key => {
      if (!allowNULL && data[key] != null) {
        return [key, data[key]].map(encodeURIComponent).join("=");
      }
    })
    .filter(val => val)
    .join("&");
}
export function replaceUnderscore(string) {
  if (!string) return "";
  let str = string.replace(/_/g, " ").trim();
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatPage(page) {
  if (parseInt(page)) return parseInt(page);
  return 1;
}

export function formatDate(date) {
  if (date) {
    return format(new Date(date), "dd/MM/yyyy");
  }
}
export function formatDateTime(datetime) {
  if (datetime) {
    return format(new Date(datetime), "dd/MM/yyyy  hh:mm a");
  }
}
export function formatWithTimeZone(datetime,  formatDate = "dd/MM/yyyy HH:mm:ss") {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate = utcToZonedTime(datetime, timeZone);
  const pattern = formatDate;
  // if (!datetime) return;
  return format(zonedDate, pattern, { timeZone });
  // return datetime;
}

export function roundNumber(num, scale) {
  if (!("" + num).includes("e")) {
    return +(Math.round(num + "e+" + scale) + "e-" + scale);
  } else {
    var arr = ("" + num).split("e");
    var sig = ""
    if (+arr[1] + scale > 0) {
      sig = "+";
    }
    return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
  }
}

export function formatNumberCurrency(number, locale = "vi", decimal = 0) {
  if (!number) return;
  if (typeof number === "number") {
    return new Intl.NumberFormat(locale, {
      maximumFractionDigits: decimal,
      minimumFractionDigits: decimal,
    }).format(number);
  }
}

export function array_column(array, column) {
  return array.map(e => e[column])
}

/**
 * Check color of temperature
 * @param array
 * @param column
 * @returns {*}
 */
export function temperature_level(temperature) {
  if (temperature > 42 || temperature < 35)
    return '#b9b9b9'
  if (temperature <= 42 && temperature >= 38)
    return 'red'

  return '#000'

}
