<template>
  <div class="login-container">
    <transition appear name="slide-fade" mode="out-in">
      <el-form v-if="!step" key="mailOtpForm" ref="mailOtpForm" :model="mailOtpForm" :rules="mailOtpFormRule"
        class="login-form" auto-complete="on" @submit.prevent.native="handleMailOtp">
        <div class="title-container">
          <img src="@/assets/icons/logo-signin.svg" alt="log-in" />
        </div>

        <p class="login-note">
          Please enter your phone number to receive a
          <br />
          verification code from WhatsApp
        </p>

        <el-form-item prop="email">
          <el-input v-model.trim="mailOtpForm.email" placeholder="Phone number">
            <i slot="prefix" class="el-icon-phone-outline"></i>
          </el-input>
        </el-form-item>

        <el-button :disabled="loading" :loading="loading" type="primary" style="width: 100%; margin-bottom: 30px"
          @click="handleMailOtp">
          Continue
        </el-button>
      </el-form>

      <el-form v-if="step" key="loginForm" ref="loginForm" :model="loginForm" class="login-form" auto-complete="on"
        @submit.prevent.native="handleLogin">
        <div class="title-container">
          <a href="/">
            <img src="@/assets/icons/logo-signin.svg" alt="log-in" />
          </a>
        </div>

        <p class="login-note">
          Verification code will be sent to your WhatsApp with
          <br />
          <strong style="color: #fff; font-weight: 400">{{ mailOtpForm.email }}
          </strong>
        </p>
        <el-form-item>
          <div class="flex justify-center">
            <OtpInput ref="otpInput" separator="" input-classes="otp-input" :num-inputs="6" :should-auto-focus="true"
              :is-input-num="true" @on-change="handleOnChange" @on-complete="handleOnComplete" />
          </div>
        </el-form-item>
        <el-button :disabled="!isCompleteOtp" :loading="loading" type="primary" style="width: 100%; margin-bottom: 23px"
          @click="handleLogin">
          Sign In
        </el-button>

        <div class="text__desc">
          <p>You didn't receive code ({{ timeResend }}s)</p>

          <span class="resend" :class="{ disabled: isDisableResend }" v-loading="loadingResend"
            element-loading-spinner="el-icon-loading" element-loading-background="transparent"
            @click="getResendOtp">Resend</span>
        </div>
      </el-form>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import OtpInput from "@bachdgvn/vue-otp-input";

export default {
  name: "Login",
  components: { OtpInput },
  data() {
    return {
      isCompleteOtp: false,
      mailOtpForm: {
        email: "",
        locale: "vi",
      },
      loginForm: {
        username: "",
        password: null,
      },
      fakeForm: {
        username: "account@demo.com",
        password: '777777',
      },
      mailOtpFormRule: {
        email: [
          {
            required: true,
            message: "Please input phone number",
            trigger: ["blur", "change"],
          },
        ],
      },
      loading: false,
      loadingResend: false,
      isDisableResend: false,
      redirect: undefined,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      timeResend: (state) => state.user.timeResend,
      idResend: (state) => state.user.idResend,
    }),

    resendAmount() {
      return process.env.VUE_APP_RESENDS_AMOUNT || 0;
    },

    step() {
      const step = this.$route.query.step;
      return (step && Number(step)) || null;
    },
  },

  destroyed() {
    this.clearIdResend({
      id: this.idResend,
    });
  },

  created() {
    if (this.step) {
      this.$router.replace({ path: "/login" });
    }
  },

  methods: {
    ...mapActions("user", [
      "login",
      "createMailOtp",
      "setTimeResend",
      "clearIdResend",
    ]),
    handleOnComplete(value) {
      this.loginForm.password = value;
      this.isCompleteOtp = true;
    },
    handleOnChange(value) {
      if (value.length < 6) {
        this.isCompleteOtp = false;
      }
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
      this.otp = null;
    },
    handleMailOtp() {
      this.$refs.mailOtpForm.validate(async (valid) => {
        if (valid) {
          this.loading = true;

          try {
            if (this.idResend) {
              this.clearIdResend({
                id: this.idResend,
              });
            }
            this.loginForm.username = this.mailOtpForm.email;
            this.loading = false;
            this.setTimeResend(this.resendAmount);
            this.$router.push({ path: "/login", query: { step: "2" } });
          } catch (error) {
            this.loading = false;
          }
        } else {
          this.loading = false;
          return false;
        }
      });
    },

    async getResendOtp() {
      this.isDisableResend = true;
      this.loadingResend = true;
      try {
        await this.createMailOtp(this.mailOtpForm);
        this.handleClearInput();
        this.clearIdResend({
          id: this.idResend,
          time: this.resendAmount,
        });

        this.loadingResend = false;

        this.$notify.success({
          title: "success",
          message: "Verification Code has been resent to this phone number on your WhatsApp",
        });
        setTimeout(() => {
          this.isDisableResend = false;
        }, 20000);
      } catch (error) {
        this.isDisableResend = false;
        this.loadingResend = false;
      }
    },

    handleLogin() {
      if (!this.isCompleteOtp) return;
      this.loading = true;
      this.login(this.fakeForm)
        .then(() => {
          this.loading = false;
          this.$router.replace({ path: "/" });
        })
        .catch((error) => {
          this.loading = false;
          this.isCompleteOtp = false;
          this.$notify.error({
            title: "Error",
            message: 'Phone number or otp is incorrect',
          });
        });
    },

    handleErrorMessage(error) {
      let { hint } = error.response?.data;
      if (!hint) {
        let { message } = error.response?.data;
        if (message) {
          return this.$notify.error({
            title: "Error",
            message: message,
          });
        }
        message = [];
        Object.values(error.response?.data).map((value) => {
          const errors = value.join("\n");
          message.push(errors);
        });
        return this.$notify.error({
          title: "Error",
          message: message.join("\n"),
        });
      }
      return this.$notify.error({
        title: "Error",
        message: hint,
      });
    },
  },
};
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #19304c;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 7px;
  font-size: 20px;
  border-radius: 4px;
  font-weight: 400;
  outline: none;
  text-align: center;

  &.error {
    border: 1px solid red !important;
  }
}

/* reset element-ui css */
.login-container {
  .el-input__inner {
    &:-webkit-autofill {
      box-shadow: 0 0 0px 1000px $bg inset !important;
      -webkit-text-fill-color: $cursor !important;
    }

    &:hover {
      border-color: none;
    }
  }

  input {
    background: #54657a;
    border: 1px solid #3e4957;
    -webkit-appearance: none;
    color: $cursor;
    text-transform: none;
    outline: none;
  }

  .el-form-item {
    &.is-error {
      border-color: #f56c6c;
    }
  }

  .resend {
    .el-loading-mask {
      .el-loading-spinner {
        right: 0;
        text-align: right;
        margin: 0;
        top: 0;
        margin-right: -20px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
$bg: #19304c;
$dark_gray: #889aa4;

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 420px;
    max-width: 100%;
    padding: 160px 40px 0;
    margin: 0 auto;
    overflow: hidden;
    text-align: center;
  }

  .title-container {
    margin-bottom: 36px;
  }

  .login-note {
    color: #97a8be;
    font-size: 14px;
    font-weight: 400;
    margin: 0 auto 23px;
    line-height: 1.4;
  }

  .text__desc {
    p {
      font-size: 14px;
      font-weight: 400;
      color: #97a8be;
      margin-bottom: 10px;
    }

    .resend {
      font-size: 14px;
      color: #fbad1b6e;
      font-weight: 600;
      cursor: pointer;
      text-transform: capitalize;
    }

    .resend.disabled {
      pointer-events: none;
      opacity: 0.8;
    }
  }
}
</style>
