/**
 * Created by SaliproLap on 2/27/2018.
 * @copyright: 2018 - Suga Co.,Ltd
 */

import Vue from 'vue';
export const eBus = new Vue();

/**
 * Usage
 *
 * import { eBus } from 'utils/eventbus';
 *
 * eBus.$emit('event-name', payload);
 * eBus.$on('event-name', payload => {});
 * eBus.$once('event-name', payload => {});
 * eBus.$off('event-name', payload => {});
 * eBus.$off('event-name');
 */


