<template>
  <div id="app">
    <router-view />
    <div class="app-version font-size-12 font-weight-600 blockquote">
      {{ appVersion }}_build:{{ commitHash }}
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      loading: true,
      appVersion: process.env.VUE_APP_BUILD_VERSION,
      commitHash: process.env.VUE_APP_COMMIT_HASH,
    };
  },
};
</script>
<style lang="scss" scoped>
.app-version {
  color: #9c9c9c;
  z-index: 999999;
  position: fixed;
  bottom: 5px;
  right: 15px;
  text-align: center;
}
</style>
