const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  showApprove: state => state.user.isShowApproved,
  showRequest: state => state.user.isShowRequest,
  xTotalCount: state => state.app.xTotalCount,
  minTemp: state => state.settings.minTemp,
  itemPerPage: state => state.settings.limitPerPage,
  fcmToken: state => state.app.fcmToken,
  stations: state => state.app.ourStations,
};
export default getters;
