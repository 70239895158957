<script>
import {ContentLoader} from "vue-content-loader"

export default {
  components: {ContentLoader}
}
</script>

<template>
  <content-loader
    :width=200
    :height=300
    :speed=2
    primaryColor="#6e7884"
    secondaryColor="#AAA"
  >
    <rect x="5" y="13" rx="5" ry="5" width="113" height="44" />
    <rect x="5" y="162" rx="5" ry="5" width="143" height="11" />
    <rect x="5" y="131" rx="5" ry="5" width="120" height="10" />
    <rect x="5" y="102" rx="5" ry="5" width="150" height="10" />
    <rect x="5" y="192" rx="5" ry="5" width="130" height="10" />
    <rect x="5" y="225" rx="5" ry="5" width="150" height="10" />
  </content-loader>
</template>
