<template>
  <section class="table_style">
    <ve-table
      :style="{ 'word-break': 'normal' }"
      :columns="columns"
      :table-data="tableData"
      :scrollWidth="960"
      borderY
    />
    <div v-show="showEmpty" class="empty-data">No Data</div>
  </section>
</template>

<script>
import { template } from "@babel/core";
export default {
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      showEmpty: false,
      columns: [
        {
          field: "ID",
          key: "ID",
          title: "ID",
          align: "center",
          width: 100,
          renderBodyCell: ({ row }, h) => {
            return <span> {row.id}</span>;
          },
        },
        {
          field: "Name",
          key: "Name",
          title: "Name",
          align: "center",
          width: 200,
          renderBodyCell: ({ row }, h) => {
            return <strong> {row.name}</strong>;
          },
        },

        {
          field: "Available Pockets",
          key: "Available Pockets",
          title: "Available Pockets",
          align: "center",
          width: 200,
          renderBodyCell: ({ row }, h) => {
            return (
              <span>
                {row.available_pockets_count}/{row.pockets_count}
              </span>
            );
          },
        },
        {
          field: "Connection",
          key: "Connection",
          title: "Connection",
          align: "center",
          width: 200,
          renderBodyCell: ({ row }, h) => {
            let html = row.is_online ? (
              <el-tag type="success">Online</el-tag>
            ) : (
              <el-tag type="info">Offline</el-tag>
            );
            return html;
          },
        },
      ],
    };
  },
  created() {
    this.handleEmptyData();
  },
  watch: {
    tableData() {
      this.handleEmptyData();
    },
  },
  methods: {
    handleEmptyData() {
      if (this.tableData.length === 0) {
        this.showEmpty = true;
      } else {
        this.showEmpty = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  color: #666;
  font-size: 16px;
  border: 1px solid #eee;
  border-top: 0;
}
</style>
