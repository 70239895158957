<template>
  <div class="sidebar-logo-container" :class="{ collapse: collapse }">
    <router-link key="collapse" class="sidebar-logo-link" to="/">
      <transition name="sidebarLogoFade">
        <h1 v-show="!collapse" class="sidebar-title">
          <img src="@/assets/icons/logo.svg" alt="profile" />
        </h1>
      </transition>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "SidebarLogo",
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      logo: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s 0.2s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 90px;
  overflow: hidden;
  background-color: #3a506b;

  & .sidebar-logo-link {
    display: block;
    height: 100%;
    & .sidebar-title {
      font-size: 20px;
      color: #fff;
      line-height: 30px;
      margin: 0;
      height: 100%;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
