var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-version font-size-12 font-weight-600 blockquote" },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.appVersion) +
              "_build:" +
              _vm._s(_vm.commitHash) +
              "\n  "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }