import Cookies from 'js-cookie'

const state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  xTotalCount: 0,
  fcmToken: Cookies.get("fcmToken") || null,
  ourStations: [],
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1)
    } else {
      Cookies.set('sidebarStatus', 0)
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  X_TOTAL_COUNT: (state, number) => {
    state.xTotalCount = number
  },
  FCM_TOKEN: (state, tk) => {
    state.fcmToken = tk
  },
  
  STATIONS: (state, payload) => {
    state.ourStations = payload
  },
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  setXTotalCount({ commit }, num) {
    commit('X_TOTAL_COUNT', num)
  },
  
  setFCMToken({ commit }, num) {
    commit('FCM_TOKEN', num)
    Cookies.set("fcmToken", num);
  },
  
  setStations({ commit }, num) {
    commit('STATIONS', num)
  }
  
  
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
