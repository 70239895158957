<script>
import { isExternal } from "@/utils/validate";

export default {
  name: "TagLink",
  functional: true,

  props: {
    baseUrl: {
      type: String,
      default: "",
      required: true,
    },
    isNewTab: {
      type: Boolean,
      default: false,
    },
    content: {
      type: [String, Number, Array],
      default: "",
    },
  },

  render(h, { props, children, data }) {
    const { isNewTab, content, baseUrl } = props;
    const targetUrl = isNewTab ? "_blank" : "_self";
    const className = data.staticClass;
    const style = data.staticStyle;
    let contentDefault;

    if (Array.isArray(content)) {
      contentDefault = content.map((item) => {
        return h("span", [item]);
      });
    } else {
      contentDefault = h("span", [content]);
    }

    const contentDifferent = children || contentDefault;

    function nativeClickHandler(e) {
      if (isNewTab) {
        e.preventDefault();
        window.open(baseUrl, [targetUrl]);
      }
    }

    if (isExternal(baseUrl)) {
      return h(
        "a",
        {
          attrs: {
            href: baseUrl,
            target: targetUrl,
          },
          class: className,
          style,
        },
        [contentDifferent]
      );
    }
    return h(
      "router-link",
      {
        props: { to: baseUrl },
        class: className,
        style,
        nativeOn: {
          "!click": nativeClickHandler,
        },
      },
      [contentDifferent]
    );
  },
};
</script>
