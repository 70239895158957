<template>
  <div :class="{'has-logo':showLogo}">
    <sidebar-loader
      v-if="loading"
      class="sidebar-loader"
    />

    <template v-else>
      <logo
        v-show="showLogo"
        :collapse="isCollapse"
      />
      <el-scrollbar
        wrap-class="scrollbar-wrapper"
        class=""
      >
        <el-menu
          :default-active="activeMenu"
          :collapse="isCollapse"
          :background-color="variables.menuBg"
          :text-color="variables.subMenuActiveText"
          :unique-opened="false"
          :active-text-color="variables.menuActiveText"
          :collapse-transition="false"
          mode="vertical"
        >
          <sidebar-item
            v-for="route in routes"
            :key="route.path"
            :item="route"
            :base-path="route.path"
            :is-master="is_master"
          />
        </el-menu>
      </el-scrollbar>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'
import SidebarLoader from "../../../components/SidebarLoader";
import { account as accountAPI } from "@/api/request";

export default {
  components: {SidebarLoader, SidebarItem, Logo, },
  data () {
    return {      loading: true,
      is_master: false,
      appVersion: process.env.VUE_APP_BUILD_VERSION,
      commitHash: process.env.VUE_APP_COMMIT_HASH    }
  },
  computed: {
    ...mapGetters([
      'sidebar'
    ]),
    routes () {
      return this.$router.options.routes
    },
    activeMenu () {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo () {
      return this.sidebar.opened
    },
    variables () {
      return variables
    },
    isCollapse () {
      return !this.sidebar.opened
    }
  },
  created () {
    this.getProfile();
    setTimeout(() => {
      this.loading = false
    }, 500)
  },
  methods: {
    getProfile() {
      this.loading = true;
      accountAPI.get().then(res => {
        this.is_master = res.data.is_master
        this.loading = false;
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.border-top-sidebar {
  border-top: 1px solid #bfcbd999;
}
.sidebar-loader {
  padding: 20px;
}

</style>
