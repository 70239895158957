var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse }
    },
    [
      _c(
        "router-link",
        {
          key: "collapse",
          staticClass: "sidebar-logo-link",
          attrs: { to: "/" }
        },
        [
          _c("transition", { attrs: { name: "sidebarLogoFade" } }, [
            _c(
              "h1",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.collapse,
                    expression: "!collapse"
                  }
                ],
                staticClass: "sidebar-title"
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/icons/logo.svg"),
                    alt: "profile"
                  }
                })
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }