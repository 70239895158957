<template>
  <div class="navbar">
    <div v-if="loading" style="height: 50px" class="p-0">
      <content-loader
        :speed="2"
        :width="300"
        :height="50"
        background-color="#f3f3f3"
        foreground-color="#ecebeb"
        style="height: 50px; width: auto"
      >
        <rect x="20" y="15" rx="3" ry="3" width="20" height="15" />
        <rect x="60" y="15" rx="3" ry="3" width="100" height="15" />
        <rect x="170" y="15" rx="3" ry="3" width="40" height="15" />
      </content-loader>
    </div>
    <div v-else>
      <hamburger
        :is-active="sidebar.opened"
        class="hamburger-container"
        @toggleClick="toggleSideBar"
      />
      <breadcrumb class="breadcrumb-container" />
    </div>

    <div class="right-menu">
      <template v-if="loading">
        <content-loader
          :speed="2"
          :width="120"
          :height="50"
          background-color="#f3f3f3"
          foreground-color="#ecebeb"
          style="height: 50px; width: auto"
        >
          <rect x="0" y="15" rx="3" ry="3" width="100" height="15" />
        </content-loader>
      </template>
      <el-dropdown
        v-else
        class="avatar-container clickable is-flex align-center"
        trigger="click"
      >
        <div class="avatar-wrapper clickable">
          <!-- <span class="clickable"> -->
          <span class="user-name"> {{ name }}</span>
          <!-- <img
            :src="avatar"
            class="user-avatar"
          > -->
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <!-- <router-link to="/profile">
            <el-dropdown-item>Profile</el-dropdown-item>
          </router-link> -->
          <el-dropdown-item icon="">
            <span style="display: block" @click="logout">Logout</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";
import { ContentLoader } from "vue-content-loader";

export default {
  components: {
    Breadcrumb,
    Hamburger,
    ContentLoader,
  },
  data() {
    return { loading: true };
  },

  computed: {
    ...mapGetters(["sidebar", "name"]),
  },
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 500);
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
    max-width: 38%;
  }

  .right-menu {
    height: 100%;
    max-width: 50%;
    line-height: 50px;
    position: absolute;
    right: 0;
    top: 0;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        position: relative;
        .user-name {
          font-weight: 500;
        }
        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 20px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
