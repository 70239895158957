import user from "@/api/modules/auth/user";
import delivery from "@/api/modules/delivery";
import cabinet from "@/api/modules/cabinet";
import account from "@/api/modules/account/account";
import mailotp from "@/api/modules/mailotp";

export {
  user,
  delivery,
  cabinet,
  account,
  mailotp,
};
