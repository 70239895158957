module.exports = {
  title: "SugaMobile Dashboard",

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,
  /**
   * @type {Number}
   * @description Limit items per page
   */
  limitPerPage: 50,

  /**
   * @type {Number}
   * @description Temperature on oC
   */
  minTemp: 37.8
};
