var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "el-card-no-border" },
        [
          _c(
            "keep-alive",
            [
              _c(_vm.tabList, {
                key: _vm.tabList,
                ref: { tabList: _vm.tabList },
                tag: "component",
                attrs: { queryprops: _vm.queryprops },
                on: {
                  "update:queryprops": function($event) {
                    _vm.queryprops = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }