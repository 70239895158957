<template>
  <div class="w-100">
    <CodeLoader
      v-if="loading"
      :width="600"
      :height="80"
      :speed="2"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    />
    <template v-else>
      <DeliveryTable :tableData="listDelivery" />
      <el-pagination
        class="flex flex-end"
        background
        layout="total, prev, pager, next"
        style="margin-top: 20px; justify-content: flex-end"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="total"
        :pager-count="pager_count"
        :small="pagination_small"
        @current-change="onChangePage"
      />
    </template>
  </div>
</template>

<script>
import { delivery as deliveryAPI } from "@/api/request";
import DeliveryTable from "../components/DeliveryTable.vue";

export default {
  components: { DeliveryTable },
  props: {
    queryprops: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      listDelivery: [],
      loading: false,
      total: 0,
      pager_count: 7,
      currentPage: 1,
      pageSize: 0,
      pagination_small: false,
      query: {}
    };
  },
  computed: {},
  watch: {
    //# trigger when queryprops prop changes
    queryprops: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        //console.log('queryprops',newValue,oldValue)
        //#reset query
        this.query = {};
        //# void updating route while refresh browser
        if (!oldValue) return;
        this.updateRoute(newValue);
      }
    },
    //# reacting to param url changes (updateRoute)
    "$route.query"() {
      if (Object.keys(this.$route.query).length === 0) {
        this.query = {};
      }
      this.initParams();
      this.getDeliveries();
    }
  },
  mounted() {
    this.pager_count = window.innerWidth > 540 ? 7 : 5;
    this.pagination_small = window.innerWidth > 768 ? false : true;
    window.addEventListener("resize", this.resizeWindow);
  },
  created() {
    this.initParams();
    this.getDeliveries();
  },
  methods: {
    resizeWindow() {
      this.pager_count = window.innerWidth > 540 ? 7 : 5;
      this.pagination_small = window.innerWidth > 768 ? false : true;
    },
    addRoundBrackets(data) {
      return data ? "(" + data + ")" : "";
    },
    linkUrl(data) {
      return `/deliveries/${data}`;
    },
    formatDate(date) {
      return this.$helper.formatDate(date);
    },
    formatDateTime(datetime) {
      return this.$helper.formatWithTimeZone(datetime);
    },
    handleErrorMessage(error) {
      return this.$notify.error({
        title: "Error",
        message: error.message
      });
    },
    initParams() {
      this.query.limit = this.pageSize = this.$store.getters.itemPerPage;
      this.query.page = this.currentPage = this.$route.query.page
        ? this.$helper.formatPage(this.$route.query.page)
        : 1;

      this.query.dir = "desc";
      if (this.queryprops.from_date && this.queryprops.to_date) {
        this.query.from_date = this.queryprops.from_date;
        this.query.to_date = this.queryprops.to_date;
      }
      if (this.queryprops.status) {
        this.query.status = this.queryprops.status;
      }
      if (this.queryprops.cabinet_id) {
        this.query.cabinet_id = this.queryprops.cabinet_id;
      }
      if (this.queryprops.q) {
        this.query.q = this.queryprops.q;
      }
      if (this.queryprops.sort) {
        this.query.sort = this.queryprops.sort;
      }
      if (this.queryprops.type) {
        this.query.type = this.queryprops.type;
      }
    },

    //# transform query string on browser
    initQueryStr(query) {
      query = { ...query, ...this.queryprops };
      let queryStr = {};
      const allowedQuery = [
        "from_date",
        "to_date",
        "page",
        "limit",
        "status",
        "q",
        "cabinet_id",
        "sort",
        "type"
      ];
      for (const key of allowedQuery) {
        if (query[key]) {
          queryStr[key] = query[key];
        }
      }
      return queryStr;
    },
    updateRoute(query) {
      this.$router.replace({
        name: "Deliveries",
        query: this.initQueryStr(query)
      });
    },
    onChangePage(page) {
      this.query.page = this.currentPage = this.$helper.formatPage(page);
      this.updateRoute(this.query);
    },

    async getDeliveries() {
      this.loading = true;
      try {
        const result = await deliveryAPI.getAll(this.query);
        this.listDelivery = [...result.data];
        this.listDelivery.map(delivery => {
          const finalFee =
            delivery.receiving_price +
            delivery.charged_amount -
            delivery.refunded_amount;
          delivery.finalFee = this.$helper.formatNumberCurrency(finalFee);
        });
        this.total = parseInt(this.$store.getters.xTotalCount);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.handleErrorMessage(error);
      }
    }
  }
};
</script>
