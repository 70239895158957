import axios, { get, post, put } from "axios";
import { urlQueryString } from "@/utils";

const prefix = "deliveries";
export default {
  getAll(data) {
    const url = urlQueryString(data, false);
    return get(`${prefix}?${url}`, data);
  },
  getStatus() {
    return get(`${prefix}/statuses`);
  },
  get(id) {
    return get(`${prefix}/${id}`);
  },
  cancelDelivery(delivery_id) {
    return post(`deliveries/${delivery_id}/cancel`)
  },
  refundDelivery(delivery_id) {
    return post(`deliveries/${delivery_id}/refund`)
  },
  openPocketDelivery(delivery_id) {
    return post(`deliveries/${delivery_id}/pocket/open`)
  },
  exportDelivery(data) {
    const url = urlQueryString(data, false);
    return get(`${prefix}/export?${url}`, { responseType: 'blob' })
  },

};
