var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _vm.loading
        ? _c("sidebar-loader", { staticClass: "sidebar-loader" })
        : [
            _c("logo", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showLogo,
                  expression: "showLogo"
                }
              ],
              attrs: { collapse: _vm.isCollapse }
            }),
            _vm._v(" "),
            _c(
              "el-scrollbar",
              { attrs: { "wrap-class": "scrollbar-wrapper" } },
              [
                _c(
                  "el-menu",
                  {
                    attrs: {
                      "default-active": _vm.activeMenu,
                      collapse: _vm.isCollapse,
                      "background-color": _vm.variables.menuBg,
                      "text-color": _vm.variables.subMenuActiveText,
                      "unique-opened": false,
                      "active-text-color": _vm.variables.menuActiveText,
                      "collapse-transition": false,
                      mode: "vertical"
                    }
                  },
                  _vm._l(_vm.routes, function(route) {
                    return _c("sidebar-item", {
                      key: route.path,
                      attrs: {
                        item: route,
                        "base-path": route.path,
                        "is-master": _vm.is_master
                      }
                    })
                  }),
                  1
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }