import defaultSettings from "@/settings";

const { showSettings, fixedHeader, limitPerPage, minTemp } = defaultSettings;

const state = {
  limitPerPage,
  minTemp,
  showSettings: showSettings,
  fixedHeader: fixedHeader,
  imagePlaceholder: require("@/assets/images/placeholder-image.png")
};

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  }
};

const actions = {
  changeSetting({ commit }, data) {
    commit("CHANGE_SETTING", data);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
