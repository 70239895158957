var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar" }, [
    _vm.loading
      ? _c(
          "div",
          { staticClass: "p-0", staticStyle: { height: "50px" } },
          [
            _c(
              "content-loader",
              {
                staticStyle: { height: "50px", width: "auto" },
                attrs: {
                  speed: 2,
                  width: 300,
                  height: 50,
                  "background-color": "#f3f3f3",
                  "foreground-color": "#ecebeb"
                }
              },
              [
                _c("rect", {
                  attrs: {
                    x: "20",
                    y: "15",
                    rx: "3",
                    ry: "3",
                    width: "20",
                    height: "15"
                  }
                }),
                _vm._v(" "),
                _c("rect", {
                  attrs: {
                    x: "60",
                    y: "15",
                    rx: "3",
                    ry: "3",
                    width: "100",
                    height: "15"
                  }
                }),
                _vm._v(" "),
                _c("rect", {
                  attrs: {
                    x: "170",
                    y: "15",
                    rx: "3",
                    ry: "3",
                    width: "40",
                    height: "15"
                  }
                })
              ]
            )
          ],
          1
        )
      : _c(
          "div",
          [
            _c("hamburger", {
              staticClass: "hamburger-container",
              attrs: { "is-active": _vm.sidebar.opened },
              on: { toggleClick: _vm.toggleSideBar }
            }),
            _vm._v(" "),
            _c("breadcrumb", { staticClass: "breadcrumb-container" })
          ],
          1
        ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "right-menu" },
      [
        _vm.loading
          ? [
              _c(
                "content-loader",
                {
                  staticStyle: { height: "50px", width: "auto" },
                  attrs: {
                    speed: 2,
                    width: 120,
                    height: 50,
                    "background-color": "#f3f3f3",
                    "foreground-color": "#ecebeb"
                  }
                },
                [
                  _c("rect", {
                    attrs: {
                      x: "0",
                      y: "15",
                      rx: "3",
                      ry: "3",
                      width: "100",
                      height: "15"
                    }
                  })
                ]
              )
            ]
          : _c(
              "el-dropdown",
              {
                staticClass: "avatar-container clickable is-flex align-center",
                attrs: { trigger: "click" }
              },
              [
                _c("div", { staticClass: "avatar-wrapper clickable" }, [
                  _c("span", { staticClass: "user-name" }, [
                    _vm._v(" " + _vm._s(_vm.name))
                  ]),
                  _vm._v(" "),
                  _c("i", { staticClass: "el-icon-caret-bottom" })
                ]),
                _vm._v(" "),
                _c(
                  "el-dropdown-menu",
                  {
                    staticClass: "user-dropdown",
                    attrs: { slot: "dropdown" },
                    slot: "dropdown"
                  },
                  [
                    _c("el-dropdown-item", { attrs: { icon: "" } }, [
                      _c(
                        "span",
                        {
                          staticStyle: { display: "block" },
                          on: { click: _vm.logout }
                        },
                        [_vm._v("Logout")]
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }