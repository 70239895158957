<template>
  <div class="w-100">
    <CodeLoader
      v-if="loading"
      :width="600"
      :height="80"
      :speed="2"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    />

    <template v-else>
      <cabinet-table :tableData="cabinetList" />
      <el-pagination
        class="flex flex-end"
        background
        layout="total, prev, pager, next"
        style="margin-top: 20px; justify-content: flex-end"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="total"
        @current-change="onChangePage"
      />
    </template>
  </div>
</template>

<script>
import { cabinet as cabinetAPI } from "@/api/request";
import { eBus } from "@/utils/eventbus";
import CabinetTable from "./CabinetTable.vue";

export default {
  components: { CabinetTable },
  props: {
    queryprops: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cabinetList: [],
      loading: false,
      total: 0,
      currentPage: 1,
      pageSize: 0,
      query: {},
    };
  },
  watch: {
    //# trigger when queryprops prop changes
    queryprops: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        //#reset query
        this.query = {};
        //# void updating route while refresh browser
        if (!oldValue) return;
        this.updateRoute(newValue);
      },
    },
    //# reacting to param url changes (updateRoute)
    "$route.query"() {
      this.initParams();
      this.getCabinet();
    },
  },
  created() {
    eBus.$on("reload-cabinet", () => this.getCabinet());
    this.initParams();
    this.getCabinet();
  },
  methods: {
    handleErrorMessage(error) {
      return this.$notify.error({
        title: "Error",
        message: error,
      });
    },

    initParams() {
      this.query.limit = this.pageSize = this.$store.getters.itemPerPage;
      this.query.page = this.currentPage = this.$route.query.page
        ? this.$helper.formatPage(this.$route.query.page)
        : 1;
      this.query.q = this.$route.query.q;
      this.query.status = this.$route.query.status;

      this.query.dir = "desc";
    },

    //# transform query string on browser
    initQueryStr(query) {
      query = { ...query, ...this.queryprops };
      let queryStr = {};
      const allowedQuery = ["limit", "page", "dir"];

      for (const key of allowedQuery) {
        if (query[key]) {
          queryStr[key] = query[key];
        }
      }
      return queryStr;
    },
    updateRoute(query) {
      this.$router.replace({
        name: "Cabinets",
        query: this.initQueryStr(query),
      });
    },
    onChangePage(page) {
      this.query.page = this.currentPage = this.$helper.formatPage(page);
      this.updateRoute(this.query);
    },

    async getCabinet() {
      this.loading = true;
      try {
        const result = await cabinetAPI.getAll(this.query);
        this.cabinetList = [...result.data];
        this.total = parseInt(this.$store.getters.xTotalCount);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.handleErrorMessage(error);
      }
    },
  },
};
</script>
