import { get, post, put } from "axios";
let baseURL = process.env.VUE_APP_BASE_API

const prefix = "me";
export default {
  login(data) {
    data.grant_type = "password";
    data.client_id = process.env.VUE_APP_API_CLIENT_ID;
    data.client_secret = process.env.VUE_APP_API_CLIENT_SECRET;
    data.provider = "merchants";
    return post(`oauth/token`, data, { baseURL: baseURL });
  },
  getInfo() {
    return get(`${prefix}/profile`);
  },
  updateProfile(data) {
    return put(`${prefix}/profile`, data);
  },
  changePassword(data) {
    return post(`${prefix}/password/change`, data);
  },
  logout() {
    return post(`${prefix}/logout`);
  }
};
