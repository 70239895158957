<template>
  <div class="app-container">
    <el-card class="el-card-no-border">
      <div slot="header" class="header-container">
        <el-row :gutter="8" type="flex" class="row-wrap">
          <el-col :lg="6" :md="8" :sm="12" :xs="24">
            <DatePicker
              v-model="selectedDate"
              type="daterange"
              @onChange="changeDate"
            />
          </el-col>
          <el-col :lg="6" :md="8" :sm="12" :xs="24">
            <el-select
              v-model="selectedCabinet"
              class="filter-input-res"
              style="margin-right: 10px"
              clearable
              filterable
              remote
              reserve-keyword
              :remote-method="remoteMethod"
              :loading="filterLoading"
              placeholder="Filter: Cabinet"
              @change="changeCabinet"
              @focus="preCheckField"
            >
              <el-option
                v-for="item in cabinetOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :lg="6" :md="8" :sm="12" :xs="24">
            <el-select
              v-model="selectedStatus"
              class="filter-input-res delivery-input"
              clearable
              placeholder="Filter: Status"
              @change="changeStatus"
            >
              <el-option
                v-for="(option, key) in statusOptions"
                :key="key"
                style="text-transform: capitalize"
                :value="option"
                :label="option"
              />
            </el-select>
          </el-col>
          <el-col :lg="6" :md="8" :sm="12" :xs="24">
            <el-select
              v-model="selectedSort"
              class="filter-input-res delivery-input"
              clearable
              placeholder="Sort"
              @change="changeSort"
            >
              <el-option
                v-for="option in sortsOption"
                :key="option.value"
                style="text-transform: capitalize"
                :value="option.value"
                :label="formatType(option.label)"
              />
            </el-select>
          </el-col>

          <el-col :lg="6" :md="8" :sm="12" :xs="24">
            <el-select
              v-model="selectedType"
              class="filter-input-res delivery-input"
              clearable
              placeholder="Filter: Type"
              @change="changeType"
            >
              <el-option
                v-for="option in typeOptions"
                :key="option.value"
                style="text-transform: capitalize"
                :value="option.value"
                :label="option.label"
              />
            </el-select>
          </el-col>
          <div class="sec-export el-col">
            <el-button
              size="small"
              style="
                background-color: #24d171;
                color: #ffffff;
                border-color: #24d171;
                height: 40px;
              "
              :class="{ 'w-100': device === 'mobile' }"
              :disabled="downloadLoading"
              @click="handleDownload"
            >
              Export
              <img
                src="@/assets/icons/export.svg"
                alt="export-icon"
                class="ml-6"
                style="vertical-align: bottom"
              />
            </el-button>
          </div>
        </el-row>
      </div>
      <keep-alive>
        <component
          :is="tabList"
          :ref="{ tabList }"
          :key="tabList"
          :queryprops.sync="queryprops"
        />
      </keep-alive>
    </el-card>
  </div>
</template>

<script>
import {
  delivery as deliveryAPI,
  cabinet as cabinetAPI,
  users as userAPI
} from "@/api/request";
import { replaceUnderscore } from "@/utils/index";
import { saveAs } from "file-saver";

import DatePicker from "@/components/DatePicker";

export default {
  components: {
    "record-list": () => import("./Records/Records"),
    DatePicker
  },
  data() {
    return {
      filterLoading: false,
      selectedDate: null,
      selectedStatus: null,
      selectedCabinet: null,
      selectedSort: null,
      selectedType: null,
      queryprops: {
        from_date: null,
        to_date: null,
        status: null,
        cabinet_id: null,
        sort: null,
        dir: "desc",
        type: null
      },
      statusOptions: [
        "created",
        "sending",
        "sent",
        "receiving",
        "received",
        "completed",
        "failed",
        "canceled"
      ],
      cabinetOptions: [],
      sortsOption: [
        {
          label: "last_created",
          value: "created_at"
        },
        {
          label: "last_updated",
          value: "updated_at"
        }
      ],
      userOptions: [],
      downloadLoading: false,
      typeOptions: [
        {
          label: "Default",
          value: "default"
        },
        {
          label: "Remote Rent",
          value: "rent_pocket"
        },
        {
          label: "Self Rent",
          value: "self_rent_pocket"
        }
      ]
    };
  },
  computed: {
    tabList: function() {
      return `record-list`;
    },
    device() {
      return this.$store.state.app.device;
    }
  },
  watch: {
    "$route.query"() {
      if (Object.keys(this.$route.query).length === 0) {
        this.resetAll();
      }
    }
  },
  created() {
    this.initParams();
  },

  ////////////////////////////
  methods: {
    async handleDownload() {
      this.downloadLoading = true;
      try {
        const params = this.initQueryStr(this.queryprops);
        const response = await deliveryAPI.exportDelivery({
          ...params
        });
        const effectiveFileName = "deliveries.xlsx";
        saveAs(response.data, effectiveFileName);
        this.downloadLoading = false;
      } catch (error) {
        this.downloadLoading = false;
        this.handleErrorMessage(error);
      }
    },
    initQueryStr(query) {
      query = { ...query, ...this.queryprops };
      let queryStr = {};
      const allowedQuery = [
        "from_date",
        "to_date",
        "status",
        "cabinet_id",
        "sort",
        "type"
      ];
      for (const key of allowedQuery) {
        if (query[key]) {
          queryStr[key] = query[key];
        }
      }
      return queryStr;
    },
    preCheckField(e) {
      let field = e.target;
      if (field.readOnly) {
        field.blur();
        field.readOnly = false;
        field.focus();
      }
    },
    resetAll() {
      this.queryprops = {
        from_date: null,
        to_date: null,
        status: null,
        cabinet_id: null,
        q: null,
        sort: null,
        type: null
      };
      this.selectedStatus = null;
      this.selectedDate = null;
      this.selectedCabinet = null;
      this.selectedSort = null;
      this.selectedType = null;
    },
    async getStatus() {
      try {
        const result = await deliveryAPI.getStatus();
        this.statusOptions = result.data;
      } catch (error) {
        this.handleErrorMessage(error);
      }
    },
    formatString(string) {
      return replaceUnderscore(string);
    },

    initParams() {
      let fromDate = this.$route.query.from_date
        ? this.$route.query.from_date
        : null;
      let toDate = this.$route.query.to_date ? this.$route.query.to_date : null;

      fromDate && toDate ? (this.selectedDate = [fromDate, toDate]) : null;
      this.queryprops.from_date = fromDate;
      this.queryprops.to_date = toDate;

      this.queryprops.status = this.selectedStatus = this.$route.query.status;
      this.queryprops.cabinet_id = this.$route.query.cabinet_id;
      this.queryprops.sort = this.selectedSort = this.$route.query.sort;
      this.queryprops.type = this.selectedType = this.$route.query.type;

      if (this.queryprops.cabinet_id) {
        cabinetAPI.get(this.queryprops.cabinet_id).then(res => {
          this.cabinetOptions.push(res.data);
          this.selectedCabinet = res.data;
        });
      }
    },
    changeDate(selected) {
      const [from_date, to_date] = selected || [];
      this.queryprops.from_date = from_date;
      this.queryprops.to_date = to_date;
    },
    changeStatus(selected) {
      this.queryprops.status = selected;
    },
    changeCabinet(selected) {
      this.queryprops.cabinet_id = selected;
    },
    remoteMethod(query) {
      if (query !== "") {
        this.filterLoading = true;
        setTimeout(() => {
          this.filterLoading = false;
          cabinetAPI.getAll({ q: query }).then(res => {
            this.cabinetOptions = res.data;
          });
        }, 200);
      } else {
        this.cabinetOptions = [];
      }
    },
    filterByUser(query) {
      if (query !== "") {
        this.filterLoading = true;
        setTimeout(() => {
          this.filterLoading = false;
          userAPI.getAll({ q: query }).then(res => {
            this.userOptions = res.data;
          });
        }, 200);
      } else {
        this.userOptions = [];
      }
    },

    changeSort(selected) {
      this.queryprops.sort = selected;
    },

    formatType(type) {
      return type.replace(/_/g, " ");
    },

    changeType(type) {
      this.queryprops.type = type;
    },

    handleErrorMessage(error) {
      return this.$notify.error({
        title: "Error",
        message: error.message
      });
    }
  }
};
</script>
<style lang="scss">
.delivery-input {
  .el-input__inner {
    text-transform: capitalize;
  }
}
</style>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.el-card-no-border {
  ::v-deep {
    .el-card__header {
      border-bottom: 0px;
    }
  }
}

.filter-input-res {
  width: 100%;
}

.el-col {
  margin-bottom: 10px;
}
.header-container {
  .row-wrap {
    .sec-export {
      padding: 0 4px;
    }
  }
}

@media (max-width: 991px) {
  .header-container {
    .row-wrap {
      flex-wrap: wrap;
      .sec-export {
        width: 50%;
      }
    }
  }
}
@media (max-width: 767px) {
  .header-container {
    .row-wrap {
      .sec-export {
        width: 100%;
      }
    }
  }
}
</style>
