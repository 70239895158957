<template>
  <section class="table_style">
    <ve-table
      :style="{ 'word-break': 'normal' }"
      :columns="columns"
      :table-data="tableData"
      :scrollWidth="960"
      borderY
    />
    <div v-show="showEmpty" class="empty-data">No Data</div>
  </section>
</template>

<script>
import TagLink from "@/components/CustomTagLink";

export default {
  components: { TagLink },
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      showEmpty: false,
      columns: [
        {
          field: "ID",
          key: "ID",
          title: "ID",
          align: "center",
          width: 100,
          renderBodyCell: ({ row }, h) => {
            return (
              <TagLink
                baseUrl={this.linkUrl(row.id)}
                content={row.id}
              ></TagLink>
            );
          },
        },
        {
          field: "Created At",
          key: "Created At",
          title: "Created At",
          align: "center",
          width: 180,
          renderBodyCell: ({ row }, h) => {
            return (
              <TagLink
                baseUrl={this.linkUrl(row.id)}
                content={this.$helper.formatWithTimeZone(row.created_at)}
              />
            );
          },
        },

        {
          field: "Updated At",
          key: "Updated At",
          title: "Updated At",
          align: "center",
          width: 180,
          renderBodyCell: ({ row }, h) => {
            return (
              <TagLink
                baseUrl={this.linkUrl(row.id)}
                content={this.$helper.formatWithTimeZone(row.updated_at)}
              />
            );
          },
        },
        {
          field: "Cabinet",
          key: "Cabinet",
          title: "Cabinet",
          align: "center",
          width: 200,
          renderBodyCell: ({ row }, h) => {
            if (row.cabinet) {
              return (
                <TagLink baseUrl={this.linkUrl(row.id)}>
                  <strong>{row.cabinet.name}</strong>
                </TagLink>
              );
            }
          },
        },
        {
          field: "Pocket",
          key: "Pocket",
          title: "Pocket",
          align: "center",
          width: 120,
          renderBodyCell: ({ row }, h) => {
            if (row.extra && row.extra.pocket && row.extra.pocket.local_id) {
              return (
                <TagLink baseUrl={this.linkUrl(row.id)}>
                  <strong>Pocket {row.extra.pocket.local_id}</strong>
                </TagLink>
              );
            }
          },
        },
        {
          field: "Sender",
          key: "Sender",
          title: "Sender",
          align: "center",
          width: 220,
          renderBodyCell: ({ row }, h) => {
            return (
              <TagLink
                baseUrl={this.linkUrl(row.id)}
                content={[
                  row.sender.phone_number,
                  this.addRoundBrackets(row.sender.name),
                ]}
              ></TagLink>
            );
          },
        },
        {
          field: "Receiver",
          key: "Receiver",
          title: "Receiver",
          align: "center",
          width: 220,
          renderBodyCell: ({ row }, h) => {
            return (
              <TagLink
                baseUrl={this.linkUrl(row.id)}
                content={[
                  row.receiver.phone_number,
                  this.addRoundBrackets(row.receiver.name),
                ]}
              ></TagLink>
            );
          },
        },
        {
          field: "Status",
          key: "Status",
          title: "Status",
          align: "center",
          width: 150,
          renderBodyCell: ({ row }, h) => {
            return (
              <TagLink baseUrl={this.linkUrl(row.id)}>
                <el-tag style="min-width: 85px" type={row.status}>
                  <span style="text-transform: capitalize">{row.status}</span>
                </el-tag>
              </TagLink>
            );
          },
        },
        {
          field: "Fee",
          key: "Fee",
          title: "Fee",
          align: "center",
          width: 150,
          renderBodyCell: ({ row }, h) => {
            return (
              <TagLink baseUrl={this.linkUrl(row.id)}>
                <span style="text-transform: capitalize">
                  {row.finalFee ? row.finalFee + "đ" : 0}
                </span>
              </TagLink>
            );
          },
        },
      ],
    };
  },

  created() {
    this.handleEmptyData();
  },

  watch: {
    tableData() {
      this.handleEmptyData();
    },
  },

  methods: {
    linkUrl(data) {
      return `/deliveries/${data}`;
    },
    addRoundBrackets(data) {
      return data ? "(" + data + ")" : "";
    },
    handleEmptyData() {
      if (this.tableData.length === 0) {
        this.showEmpty = true;
      } else {
        this.showEmpty = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  color: #666;
  font-size: 16px;
  border: 1px solid #eee;
  border-top: 0;
}
</style>
