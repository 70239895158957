var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "transition",
        { attrs: { appear: "", name: "slide-fade", mode: "out-in" } },
        [
          !_vm.step
            ? _c(
                "el-form",
                {
                  key: "mailOtpForm",
                  ref: "mailOtpForm",
                  staticClass: "login-form",
                  attrs: {
                    model: _vm.mailOtpForm,
                    rules: _vm.mailOtpFormRule,
                    "auto-complete": "on"
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.handleMailOtp($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "title-container" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/icons/logo-signin.svg"),
                        alt: "log-in"
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "login-note" }, [
                    _vm._v(
                      "\n        Please enter your phone number to receive a\n        "
                    ),
                    _c("br"),
                    _vm._v("\n        verification code from WhatsApp\n      ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "email" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "Phone number" },
                          model: {
                            value: _vm.mailOtpForm.email,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.mailOtpForm,
                                "email",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "mailOtpForm.email"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-phone-outline",
                            attrs: { slot: "prefix" },
                            slot: "prefix"
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%", "margin-bottom": "30px" },
                      attrs: {
                        disabled: _vm.loading,
                        loading: _vm.loading,
                        type: "primary"
                      },
                      on: { click: _vm.handleMailOtp }
                    },
                    [_vm._v("\n        Continue\n      ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.step
            ? _c(
                "el-form",
                {
                  key: "loginForm",
                  ref: "loginForm",
                  staticClass: "login-form",
                  attrs: { model: _vm.loginForm, "auto-complete": "on" },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.handleLogin($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "title-container" }, [
                    _c("a", { attrs: { href: "/" } }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/icons/logo-signin.svg"),
                          alt: "log-in"
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "login-note" }, [
                    _vm._v(
                      "\n        Verification code will be sent to your WhatsApp with\n        "
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "strong",
                      { staticStyle: { color: "#fff", "font-weight": "400" } },
                      [_vm._v(_vm._s(_vm.mailOtpForm.email) + "\n        ")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", [
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c("OtpInput", {
                          ref: "otpInput",
                          attrs: {
                            separator: "",
                            "input-classes": "otp-input",
                            "num-inputs": 6,
                            "should-auto-focus": true,
                            "is-input-num": true
                          },
                          on: {
                            "on-change": _vm.handleOnChange,
                            "on-complete": _vm.handleOnComplete
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%", "margin-bottom": "23px" },
                      attrs: {
                        disabled: !_vm.isCompleteOtp,
                        loading: _vm.loading,
                        type: "primary"
                      },
                      on: { click: _vm.handleLogin }
                    },
                    [_vm._v("\n        Sign In\n      ")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "text__desc" }, [
                    _c("p", [
                      _vm._v(
                        "You didn't receive code (" +
                          _vm._s(_vm.timeResend) +
                          "s)"
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loadingResend,
                            expression: "loadingResend"
                          }
                        ],
                        staticClass: "resend",
                        class: { disabled: _vm.isDisableResend },
                        attrs: {
                          "element-loading-spinner": "el-icon-loading",
                          "element-loading-background": "transparent"
                        },
                        on: { click: _vm.getResendOtp }
                      },
                      [_vm._v("Resend")]
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }