<template>
  <div class="app-container">
    <div class="w-100">
      <el-card
        v-if="loading"
        :class="{ 'height-250': loading }"
        class="el-card-no-border"
      >
        <CodeLoader
          :width="600"
          :height="80"
          :speed="2"
          primary-color="#f3f3f3"
          secondary-color="#ecebeb"
        />
      </el-card>
      <el-card
        v-else
        :class="{ 'height-250': loading }"
        class="el-card-no-border"
      >
        <div
          slot="header"
          class="align-center is-flex justify-space-between header-content"
          style="flex-wrap: wrap"
        >
          <div class="card-title clickable" @click="returnToList">
            Delivery #{{ selectedDelivery }}
          </div>
        </div>

        <el-row
          type="flex"
          style="flex-wrap: wrap"
          :gutter="20"
          class="info-user"
        >
          <el-col :xs="24" :sm="12">
            <div class="info-card">
              <div class="link-name align-center">
                <svg-icon
                  style="width: 42px; height: 42px"
                  icon-class="sender"
                />

                <h3>
                  {{ deliveryData.sender.phone_number }}
                  {{ addRoundBrackets(deliveryData.sender.name) }}
                </h3>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12">
            <div class="info-card">
              <div class="link-name align-center">
                <svg-icon
                  style="width: 42px; height: 42px"
                  icon-class="receiver"
                />
                <h3>
                  {{ deliveryData.receiver.phone_number }}
                  {{ addRoundBrackets(deliveryData.receiver.name) }}
                </h3>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="isMobile ? 24 : 12">
            <h4>Information</h4>
            <div style="padding: 20px; padding-top: 0px">
              <el-row class="field-data" type="flex" align="bottom">
                <el-col :span="6" class="field-title"> Cabinet: </el-col>
                <el-col :span="18" class="field-content">
                  {{ deliveryData.cabinet.name }}
                </el-col>
              </el-row>
              <el-row class="field-data" type="flex" align="bottom">
                <el-col :span="6" class="field-title"> Pocket: </el-col>
                <el-col :span="18" class="field-content">
                  <el-tag :type="deliveryData.pocket.state" size="mini">
                    <span style="text-transform: capitalize">
                      {{ formatType(deliveryData.pocket.state) }}</span
                    >
                  </el-tag>
                  <el-tag
                    :type="deliveryData.pocket.status + '-status'"
                    size="mini"
                    class="mr-5"
                  >
                    <span style="text-transform: capitalize">
                      {{ deliveryData.pocket.status }}</span
                    >
                  </el-tag>
                  <span
                    v-if="
                      deliveryData.extra &&
                      deliveryData.extra.pocket &&
                      deliveryData.extra.pocket.local_id
                    "
                  >
                    Pocket {{ deliveryData.extra.pocket.local_id }}
                  </span>
                </el-col>
              </el-row>
              <el-row class="field-data" type="flex" align="bottom">
                <el-col :span="6" class="field-title"> Type: </el-col>
                <el-col
                  :span="18"
                  style="display: flex; justify-content: flex-end"
                >
                  <el-tag :style="styleTag">
                    <span style="text-transform: capitalize">{{
                      delivery_type[deliveryData.type] || "Default"
                    }}</span>
                  </el-tag>
                </el-col>
              </el-row>
              <el-row class="field-data" type="flex" align="bottom">
                <el-col :span="6" class="field-title"> Status: </el-col>
                <el-col
                  :span="18"
                  style="display: flex; justify-content: flex-end"
                >
                  <el-tag
                    :type="deliveryData.status"
                    style="width: 85px; text-align: center"
                  >
                    <span style="text-transform: capitalize">{{
                      deliveryData.status
                    }}</span>
                  </el-tag>
                </el-col>
              </el-row>
              <el-row class="field-data" type="flex" align="bottom">
                <el-col :span="6" class="field-title"> Note: </el-col>
                <el-col :span="18" class="field-content">
                  {{ deliveryData.note }}
                </el-col>
              </el-row>
              <el-row class="field-data" type="flex" align="bottom">
                <el-col :span="6" class="field-title"> Sending Fee: </el-col>
                <el-col :span="18" class="field-content bold">
                  {{ formatCurrency(deliveryData.sending_price) }}
                </el-col>
              </el-row>
              <el-row class="field-data" type="flex" align="bottom">
                <el-col :span="6" class="field-title"> Estimated Fee: </el-col>
                <el-col :span="18" class="field-content bold">
                  {{ formatCurrency(deliveryData.estimated_receiving_price) }}
                </el-col>
              </el-row>
              <el-row class="field-data" type="flex" align="bottom">
                <el-col :span="6" class="field-title"> Receiving Fee: </el-col>
                <el-col :span="18" class="field-content bold">
                  {{ formatCurrency(deliveryData.receiving_price) }}
                </el-col>
              </el-row>

              <el-row class="field-data" type="flex" align="bottom">
                <el-col :span="6" class="field-title"> Charging Fee: </el-col>
                <el-col :span="18" class="field-content bold">
                  {{ formatCurrency(deliveryData.charged_amount) }}
                </el-col>
              </el-row>
              <el-row class="field-data" type="flex" align="bottom">
                <el-col :span="6" class="field-title"> Refund Amount: </el-col>
                <el-col :span="18" class="field-content bold">
                  {{ formatCurrency(deliveryData.refunded_amount) }}
                </el-col>
              </el-row>
              <el-row class="field-data" type="flex" align="bottom">
                <el-col :span="6" class="field-title"> Category: </el-col>
                <el-col
                  :span="18"
                  class="text-right"
                  v-if="deliveryData.package_category"
                >
                  <el-tag :style="styleTag">
                    <span
                      style="text-transform: capitalize"
                      class="is-flex align-center justify-center"
                    >
                      <svg-icon
                        :icon-class="packageCategoryIcon"
                        style="width: 21px; height: 21px"
                        v-if="packageCategoryIcon"
                      ></svg-icon>
                      <span
                        style="color: #606266; font-size: 14px"
                        class="ml-5"
                        >{{ deliveryData.package_category }}</span
                      >
                    </span>
                  </el-tag>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="isMobile ? 24 : 12">
            <h4>Timeline</h4>
            <el-timeline>
              <el-timeline-item
                v-for="(delivery, index) in deliveryData.status_logs"
                :key="index"
                :type="delivery.status"
                :timestamp="formatDateTime(delivery.created_at)"
              >
                <span style="text-transform: capitalize">{{
                  delivery.status
                }}</span>
              </el-timeline-item>
            </el-timeline>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import { delivery as deliveryAPI } from "@/api/request";
import { eBus } from "@/utils/eventbus";

export default {
  components: {},
  data() {
    return {
      loading: false,
      selectedDelivery: parseInt(this.$route.params.id),
      deliveryData: null,
      type: "",
      rules: {
        name: [
          {
            required: true,
            message: "Please input name",
            trigger: ["blur", "change"],
          },
          {
            max: 255,
            message: "Length must be less than 256",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Please input name",
            trigger: ["blur", "change"],
          },
          {
            max: 255,
            message: "Length must be less than 256",
            trigger: "blur",
          },
        ],
        new_password: [
          {
            required: true,
            message: "Please input name",
            trigger: ["blur", "change"],
          },
          {
            max: 255,
            message: "Length must be less than 256",
            trigger: "blur",
          },
        ],
      },
      isMobile: false,
      statusDelivery: "",

      refundStatusAccept: ["completed", "failed", "canceled"],
      dialogvisible: false,
      isConfirm: false,
      typeDialog: "",
      contentDialog: "",
      titleDialog: "",
      packageCategoryListIcon: [
        "food",
        "clothes",
        "electronics",
        "fragile",
        "others",
      ],
    };
  },
  computed: {
    styleTag() {
      return {
        "min-width": "76px",
        "box-shadow": "0px 0px 8px 0px #fbad1b26",
        "background-color": "#ffffff",
        "text-align": "center",
      };
    },
    delivery_type() {
      return {
        default: "Default",
        rent_pocket: "Remote Rent",
        self_rent_pocket: "Self Rent",
      };
    },
    packageCategoryIcon() {
      let category = this.deliveryData.package_category;
      if (!category) return;
      if (!this.packageCategoryListIcon.includes(category)) return;
      return `category-${category}`;
    },
  },
  created() {
    eBus.$on("reload-cabinet", () => this.getDelivery());
    this.getDelivery();
    this.isMobile = window.innerWidth > 768 ? false : true;
    window.addEventListener("resize", this.resizeWindow);
  },
  methods: {
    formatDateTime(datetime) {
      return this.$helper.formatWithTimeZone(datetime);
    },
    getDelivery() {
      this.loading = true;
      deliveryAPI
        .get(this.selectedDelivery)
        .then((res) => {
          this.deliveryData = res.data;
          this.statusDelivery = res.data.status;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.handleErrorMessage(error);
        });
    },
    resizeWindow() {
      this.isMobile = window.innerWidth > 768 ? false : true;
    },
    returnToList() {
      this.$router.push({ name: "Deliveries" });
    },

    formatType(type) {
      return type.replace(/_/g, " ");
    },

    addRoundBrackets(data) {
      return data ? "(" + data + ")" : "";
    },

    formatCurrency(num, unit = "đ") {
      if (!num) return 0;
      let currency = num
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return `${currency}${unit}`;
    },

    handleErrorMessage(error) {
      return this.$notify.error({
        title: "Error",
        message: error,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.card-title {
  font-size: 20px;
  font-weight: 500;
}
.field-title {
  color: gray;
  font-size: 14px;
}
.field-content {
  text-align: end;
  color: #606266;
  font-size: 14px;
}
.field-data {
  margin-bottom: 15px;
}
.info-card {
  background-color: #fff7e7;
  padding: 14px 20px;
  border-radius: 3px;
  .link-name {
    display: inline-flex;
    h3 {
      color: #606266;
      font-weight: 400;
      font-size: 14px;
      margin: 0 0 0 30px;
    }
  }
}

.bold {
  font-weight: bold;
}

.h-20 {
  height: 20px;
}

@media only screen and (max-width: 768px) {
  .header-content {
    .card-title {
      width: 100%;
    }
  }
  .info-user {
    .el-col {
      margin-bottom: 10px;
    }
  }
}
</style>
