var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "table_style" },
    [
      _c("ve-table", {
        style: { "word-break": "normal" },
        attrs: {
          columns: _vm.columns,
          "table-data": _vm.tableData,
          scrollWidth: 960,
          borderY: ""
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmpty,
              expression: "showEmpty"
            }
          ],
          staticClass: "empty-data"
        },
        [_vm._v("No Data")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }