import { user, mailotp } from "@/api/request";
import Cookies from 'js-cookie'
import {
  getToken,
  setToken,
  removeToken,
  getIsShowApp,
  setIsShowApp,
  getIsShowRequest,
  setIsShowRequest
} from "@/utils/auth";
import { resetRouter } from "@/router";

const state = {
  token: getToken(),
  name: Cookies.get("loginName") || 'Who are u?',
  avatar: "",
  isShowApproved: getIsShowApp(),
  isShowRequest: getIsShowRequest(),
  timeResend: process.env.VUE_APP_RESENDS_AMOUNT || 0,
  idResend: null,
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    let expires = process.env.VUE_APP_EXPIRES_COOKIES || 365
    state.name = name;
    Cookies.set('loginName', name, { expires: Number(expires) })
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  IS_SHOW_APP: (state, data) => {
    state.isShowApproved = data;
  },
  IS_SHOW_REQUEST: (state, data) => {
    state.isShowRequest = data;
  },
  SET__TIME__RESEND(state, time) {
    state.timeResend = time;
  },
  SET__ID__RESEND(state, id) {
    state.idResend = id;
  },
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo;
    return new Promise((resolve, reject) => {
      user
        .login({ username, password })
        .then(response => {
          const { data } = response;
          commit("SET_TOKEN", data.access_token);
          setToken(data.access_token);
          commit("SET_NAME", username);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // get user info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit("SET_TOKEN", "");
      removeToken();
      resetRouter();
      resolve();
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit("SET_TOKEN", "");
      removeToken();
      resolve();
    });
  },
  handleColumnsApprove({ commit }, data) {
    commit("IS_SHOW_APP", data);
    setIsShowApp(data);
  },
  handleColumnsRequest({ commit }, data) {
    commit("IS_SHOW_REQUEST", data);
    setIsShowRequest(data);
  },

  createMailOtp({ commit }, data) {
    return new Promise((resolve, reject) => {
      mailotp.createMailOtp(data).then(res => {
        resolve(res.data)
      }).catch(error => {
        reject(error);
      })
    })
  },
  setTimeResend({ commit, dispatch }, time) {

    const date = new Date();
    date.setTime(date.getTime() + time * 1000);
    const second = 1000;
    let timer = null;
    const countDown = new Date(date).getTime();

    if (!time || !Number(time)) return

    (function loop() {
      const now = new Date().getTime();
      const distance = countDown - now;
      const resend = Math.floor(distance / second)
      commit("SET__TIME__RESEND", resend);
      if (resend < 1) {
        commit("SET__TIME__RESEND", 0);
        return;
      }
      cancelAnimationFrame(timer);
      timer = requestAnimationFrame(() => loop(time - 1));
      dispatch("setIdResend", timer)
    })()
  },
  clearIdResend({ commit, dispatch }, { id, time }) {
    commit("SET__ID__RESEND", null)
    cancelAnimationFrame(id);
    if (time) {
      dispatch("setTimeResend", time)
    }
  },
  setIdResend({ commit }, id) {
    commit("SET__ID__RESEND", id);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
