import axios from "axios";
import store from "@/store";
import { getToken } from "@/utils/auth";
import { Message } from "element-ui";
import router from "@/router/index";
import {
  delivery,
  cabinet,
  account,
  user,
  mailotp
} from "@/api/modules/index";
let baseURL =
  process.env.VUE_APP_BASE_API +
  process.env.VUE_APP_PREFIX +
  process.env.VUE_APP_API_VERSION;

axios.defaults.baseURL = baseURL;

axios.defaults.headers = {
  Authorization: "Bearer " + getToken(),
  "Content-Type": "application/json",
  Accept: "application/json",
  "Cache-Control": "no-cache, no-store, must-revalidate",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": true
};

// request interceptor
axios.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers.Authorization = "Bearer " + getToken();
    }
    return config;
  },
  error => {
    // do something with request error
    console.log("axios erro", error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
axios.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {

    let xTotal = response.headers["x-total-count"] || 0
    store.dispatch('app/setXTotalCount', xTotal)

    return response;
    // }
  },
  error => {
    // eslint-disable-next-line
    console.log("err", error, process.env.VUE_APP_BASE_API); // for debug
    const routeName = router.history.current.path;

    //store.dispatch('app/setXTotalCount', 0)

    if (
      error.response &&
      error.response.status === 403 &&
      routeName !== "403" &&
      routeName !== "/login"
    ) {
      setTimeout(() => {
        router.replace({ path: "/403" });
      }, 500);
      return Promise.reject("Permission Denied");
    }

    if (error.response && error.response.status === 401 && routeName !== "/login" && routeName !== "/new-delivery") {

      //# no refreshToken
      store.dispatch("user/resetToken").then(() => {
        Message({
          message: 'Unauthorized',
          type: "error",
          duration: 5 * 1000
        });
        setTimeout(() => {
          location.reload();
        }, 500);
      });
      return Promise.reject(null);
    } else {
      return Promise.reject(error);
    }
  }
);


export {
  user,
  delivery,
  cabinet,
  account,
  mailotp
};
