exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"text-color": "#4a596a",
	"text-color-accent": "#fbad1b",
	"text-color-success": "#13ce66",
	"text-color-warning": "#eec335",
	"text-color-info": "#909399",
	"text-color-danger": "#ec5555",
	"text-color-cancel": "#fff",
	"menuText": "#bfcbd9",
	"menuActiveText": "#fbad1b",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "210px",
	"background-color-warning": "#FFD95B4D",
	"background-color-danger": "#EC555533",
	"background-color-cancel": "#ed2330",
	"background-delivery-status-created": "#1f4af8",
	"background-delivery-status-sending": "#23d1ed",
	"background-delivery-status-sent": "#fbad1b",
	"background-delivery-status-receiving": "#23d1ed",
	"background-delivery-status-received": "#fbad1b",
	"background-delivery-status-completed": "#2faf09",
	"background-delivery-status-failed": "#ed2330",
	"background-delivery-status-canceled": "#333",
	"background-pocket-status-opening": "#ed2330",
	"background-pocket-status-opened": "#23d1ed",
	"background-pocket-status-closed": "#2faf09",
	"background-pocket-state-available": "#4caf50",
	"background-pocket-state-opening": "#2196f3",
	"background-pocket-state-opened": "#000",
	"background-pocket-state-in_use": "#ff9800",
	"background-pocket-state-deactivated": "#f44336"
};