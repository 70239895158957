var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "content-loader",
    {
      attrs: {
        width: 200,
        height: 300,
        speed: 2,
        primaryColor: "#6e7884",
        secondaryColor: "#AAA"
      }
    },
    [
      _c("rect", {
        attrs: { x: "5", y: "13", rx: "5", ry: "5", width: "113", height: "44" }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "5",
          y: "162",
          rx: "5",
          ry: "5",
          width: "143",
          height: "11"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "5",
          y: "131",
          rx: "5",
          ry: "5",
          width: "120",
          height: "10"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "5",
          y: "102",
          rx: "5",
          ry: "5",
          width: "150",
          height: "10"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "5",
          y: "192",
          rx: "5",
          ry: "5",
          width: "130",
          height: "10"
        }
      }),
      _vm._v(" "),
      _c("rect", {
        attrs: {
          x: "5",
          y: "225",
          rx: "5",
          ry: "5",
          width: "150",
          height: "10"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }